// LOCAL
// const BackendBaseurl = 'http://127.0.0.1:8000';

// DEMO
// const BackendBaseurl = 'https://api-monespacesn-v2.withvolkeno.com';

// PROD
// const BackendBaseurl = 'https://prod.api-monespacesn-v2.withvolkeno.com';
const BackendBaseurl = 'https://api.monespacesn.volkeno.com';

export default BackendBaseurl;