import React, {Component} from 'react';
import './VillesManager.scss'
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
// import AdminRightSidebar from '../adminRightSidebar/AdminRightSidebar'
import FeatherIcon from 'feather-icons-react';
import $ from 'jquery'
import toast from 'react-hot-toast';
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';



export default class VillesManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: false,
          villes: [],
          villeInfos: [],
          isAddInProgress: false,
          isSuccessAdding: false,
          isErrorAdding: false,
          is_empty_name: false,
          updateVilleInProgress: false,
          isUpdateVilleRequestError: false,
          fields: {},
          errors: {},
          editVilleInProgress: false,
        };

        this.editVilleHandleChange = this.editVilleHandleChange.bind(this)
    }

    componentDidMount = () => {
      this.onGetVilles()
    
    }

    onGetVilles = () => {
      this.setState({
        isPageLoading: true
      })
  
      
      var config = this.state.requestConfig;
  
      axios.get(ApiBaseUrl + 'villes', config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
  
          if (response.data.success) {
            this.setState({
              villes: response.data.data,
            })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          this.setState({
            isPageLoading: false,
          })
          console.log(error)
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    goBack = (e) => {
      e.preventDefault()
      this.props.history.goBack();
    }

    onShowVilles = (row, e) => {
      this.setState({
        villeInfos: row
      })
    }

    onDeleteVille = (e,itemID) => {
      e.preventDefault()
      if(window.confirm('Confirmez-vous la suppression de cete ville ?')){
        
        var config = this.state.requestConfig
        axios.delete(`${ApiBaseUrl}villes/${itemID}`, config)
          .then(response => {
            if(response.data.success){
              this.onGetVilles()
              toast.success('Ville supprimée avec succès.',{
                duration: 5000,
                position: 'top-center',
              })
            }else{
              console.log(response)
              toast.error('Une erreur est survenue lors de la suppression.',{
                duration: 5000,
                position: 'top-center',
              })
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Une erreur est survenue lors de la suppression.',{
              duration: 5000,
              position: 'top-center',
            })
            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }
          })
      }
      
    }

    actionsFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button data-toggle="modal" data-target=".edit-ville-modal" className="action-btn oe-edit-btn color-white" onClick={(e) => this.onGetVilleInfos(e, row)}>
            <FeatherIcon icon="edit" className="edit-icon" />
          </button>

          <button className="action-btn oe-delete-btn color-white" onClick={(e) => this.onDeleteVille(e, row.id)}>
            <FeatherIcon icon="trash" className="trash-icon" />
          </button>
          
        </div>
      )
    }

    onGetVilleInfos = (e, item) => {
      e.preventDefault()
      this.setState({
        villeInfos: item
      })
    }


    addVilleHandleChange = (e) => {
      let fields = this.state.fields

      fields[e.target.name] = e.target.value

      this.setState({
        fields
      })

      console.log(fields)
    }

    onValidateAddVilleForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['name']){
        isValidForm = false
        this.setState({
          is_empty_name: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_name: false
          })
        }, 5000);
        
        errors['name'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    getFormData = object => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);

      return formData;
    }, new FormData());
    
    onSubmitAddVille = (e) => {
      e.preventDefault()
      if(this.onValidateAddVilleForm()){
        this.setState({
          isAddInProgress: true
        })

        let data = this.state.fields;


        axios.post(`${ApiBaseUrl}villes`, data)
          .then(response => {
            this.setState({
              isAddInProgress: false
            })
            if(response.data.success){
              this.onGetVilles()
              document.getElementById('add-ville-form').reset()
              $('.close').click()
              toast.success('Antenne ajoutée avec succès', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              isAddInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              // Styling
              style: {},
              className: '',
              // Custom Icon
              // icon: '👏',
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              // Aria
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }
    }

    editVilleHandleChange = (e) => {
      e.persist()
      var name = e.target.name
      var value = e.target.value
      // console.log(value)
      this.setState((prevState) => ({
        villeInfos: { ...prevState.villeInfos, [name]: value }
      }))
    }

    onValidateEditVilleForm = () => {
      let fields = this.state.villeInfos
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['name']){
        isValidForm = false
        this.setState({
          is_empty_name: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_name: false
          })
        }, 5000);
        
        errors['name'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitEditVille = (e) => {
      e.preventDefault()

      if(this.onValidateEditVilleForm()){
        this.setState({
          editVilleInProgress: true
        })

        let data = this.state.villeInfos;

        axios.put(`${ApiBaseUrl}villes/${this.state.villeInfos.id}`, data)
          .then(response => {
            this.setState({
              editVilleInProgress: false
            })
            if(response.data.success){
              this.onGetVilles()
              $('.close').click()
              $('#edit_ville_name').val('')
              this.setState({
                villeInfos: []
              })
              toast.success('Antenne modifiée avec succès', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de la modification.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              editVilleInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              // Styling
              style: {},
              className: '',
              // Custom Icon
              // icon: '👏',
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              // Aria
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }

    }

    isActiveFormatter = (cell, row) => {
      if(cell === true || row.is_active === 1){
        return <span className="badge badge-success">actif</span>
      }
      return <span className="badge badge-danger">inactif</span>
    }


    render() {
      const options = {
        noDataText: 'Aucun article trouvé.'
        // withoutNoDataText: true, // this will make the noDataText hidden, means only showing the table header
      };

      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        return(
          <div className="component-liste-conseillers-oe">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  {/* <div className="col-md-2 itm-center" onClick={(e) => this.goBack(e)}>
                    <div className="go-back">
                      <div className="row">
                        <i className="fas fa-arrow-alt-circle-left color-white fs-25 mr-2"></i>
                        <p className="color-white mb-0 lh-1-5">Retour</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3">Liste des antennes Campus France</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                    <div className="row page-content-row">
                      <div className="col-md-6 offset-md-3 orm-add-btn-col">
                        <button className="orm-add-btn btn btn-primary scrapping-siblings" data-toggle="modal" data-target=".add-ville-modal">
                          <FeatherIcon icon="plus" className="add-plus-icon" />
                        </button>
                      </div>

                      <div className="col-md-12 cm-col oe-liste-conseiller-table-container">
                        <BootstrapTable data={this.state.villes} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>News ID</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataField='name'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Antennes</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.isActiveFormatter.bind(this)}
                              dataField='is_active'>
                              Statut</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.actionsFormatter.bind(this)} dataField='id'>Actions</TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
            {/* <AdminRightSidebar isAdmin={true} /> */}

            {/* Add ville modal */}
            <div className="modal fade add-ville-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter une antenne</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form id="add-ville-form">
                      <div className="row add-event-row">
                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="ville_name">Nom de la ville de l'antenne</label>
                          <input onChange={this.addVilleHandleChange} type="text" placeholder="Nom de la ville" className="form-control add-event-form-control" name="name" id="ville_name" />
                        
                          {this.state.is_empty_name &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.name } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.isAddInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddVille(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div className="modal fade edit-ville-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Modifier une antenne</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="edit_ville_name">Nom de la ville de l'antenne</label>
                          <input value={this.state.villeInfos.name} onChange={(e) => this.editVilleHandleChange(e)} type="text" placeholder="Nom de la ville" className="form-control add-event-form-control" name="name" id="edit_ville_name" />
                        
                          {this.state.is_empty_name &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.name } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.editVilleInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitEditVille(e) }>
                              <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            

            {/* {this.state.isNewsDeletedSuccess || this.state.isSuccessScrapping || this.state.isErrorScrapping ?
              <ToastContainer /> : ''
            } */}
          </div>
        )
      }
    }
  }