import React, {Component, Fragment} from 'react';
import './BoiteReceptionMessagerie.scss'
import axios from 'axios';
// import { NavLink } from 'react-router-dom';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import Talk from "talkjs";
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import { toast, ToastContainer } from 'react-toastify';
import $ from 'jquery'
import copy from "copy-to-clipboard";  
import CopyNotification from '../copyNotification/CopyNotification'
import LocalClock from '../localClock/LocalClock'
import moment from 'moment'
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import talkjsID from '../../http/TalkJSInfos';

export default class BoiteReceptionMessagerie extends Component {
    constructor(props) {
        super(props);
        
        this.inbox = undefined;
        let currentUser;
        const currentTalkjsUser = window.sessionStorage.getItem('currentTalkjsUser');
        if (currentTalkjsUser) {
            currentUser = JSON.parse(currentTalkjsUser)
        }

        this.state = {
          token: window.sessionStorage.getItem('token'),
          userAvatar: window.sessionStorage.getItem('user_avatar'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userRole: window.sessionStorage.getItem('user_role'),
          currentUser,
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: true,
          isSessionExpiration: false,
          isActiveChat: false,
          updateChatStatusInProgress: false,
          isSuccessUpdateChat: false,
          isErrorUpdateChat: false,
          fields: {},
          errors: {},
          is_empty_event_type: false,
          is_empty_glink_email: false,
          gLinkInProgress: false,
          isCreateLinkSuccess: false,
          isCreateLinkError: false,
          isShowOrientationList: false,
          isShowRemplissageList: false,
          comingOrientations: [],
          countComingOrientations: 0,
          comingSeanceDeRemplissages: [],
          countComingSeanceDeRemplissages: 0,
          is_invalid_gLink_email: false,
          generatedLink: null,
          isLinkCreated: false,
          showFormAfterLinkCreatedSuccessfully: false,
          isCreateLinkErrorLimitReached: false,
          isSuccessCopied: false,
          comingInformations: [],
          countComingInformations: 0,
          isEmptyComingInformation: false,
          comingSoonComplementDossier: [],
          comingPreparationDepart: [],
          countComingPreparationDepart: 0,
          countComingSoonComplementDossier: 0,
          isShowPreparationList: false,
          isShowComplementList: false,
          isShowCOnstructionProjetList: false,
          submitGLinkInProgress: false,
          comingPrivateConstructionProjet: [],
          countComingPrivateConstructionProjet: 0,
          isEmptyComingPrivateConstructionDossier: false,
          comingPublicConstructionProjet: [],
          countComingPublicConstructionProjet: 0,
          isEmptyComingPublicConstructionDossier: false,
          comingSoonOrientationIndiv: [],
          countComingSoonOrientationIndiv: 0,
          isEmptyComingSoonOrientationIndiv: false,
          isShowOrientationIndiv: false
        };

        this.onGetEnabledOrDisableChat = this.onGetEnabledOrDisableChat.bind(this)
        this.gLinkHandleChange = this.gLinkHandleChange.bind(this)
        this.onSubmitGLink = this.onSubmitGLink.bind(this)
    }

    componentDidMount(){
      this.onGetChatStatus()
      this.onCreateTalkContext()
      this.onGetSeanceDeRemplissage()
      this.onGetOrientations()
      this.onGetInformations()
      this.onGetSeancePreparationDepart()
      this.onGetSeanceComplementDossier()
      this.onGetPrivateConstructionProjet()
      this.onGetSeanceOrientationIndividuellePublic()
    }

    onGetSeanceDeRemplissage = () => {
      axios.get(`${ApiBaseUrl}coming-seances-remplissages`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingSeanceDeRemplissages: response.data.data,
                      countComingSeanceDeRemplissages: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                      isEmptyComingSeanceDeRemplissages: true
                  })
              }
          })
    }

    onGetOrientations = () => {
      axios.get(`${ApiBaseUrl}coming-orientations`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                console.log('orientations', response.data.data)
                  this.setState({
                      comingOrientations: response.data.data,
                      countComingOrientations: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                      isEmptyComingOrientation: true
                  })
              }
          })
    }

    onGetPrivateConstructionProjet = () => {
      axios.get(`${ApiBaseUrl}coming-public-construction-projet`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingPrivateConstructionProjet: response.data.data,
                      countComingPrivateConstructionProjet: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                    isEmptyComingPrivateConstructionDossier: true
                  })
              }
          })
    }

    onGetPublicConstructionProjet = () => {
      

      axios.get(`${ApiBaseUrl}coming-public-construction-projet`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingPublicConstructionProjet: response.data.data,
                      countComingPublicConstructionProjet: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                    isEmptyComingPublicConstructionDossier: true
                  })
              }
          })
    }

    onGetInformations = () => {
      

      axios.get(`${ApiBaseUrl}coming-sessions-information`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingInformations: response.data.data,
                      countComingInformations: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                      isEmptyComingInformation: true
                  })
              }
          })
    } 

    onRenderSeanceOrientationView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
              {this.state.countComingOrientations === 0 ?
                  <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                          <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                          />
                      </div>
                      <div className="col-md-10 empty-textual-col">
                          <p><strong>Désolé! Aucune séance d'orientation n'est prévue pour le moment.</strong></p>
                      </div>
                  </div> :
                  this.state.comingOrientations.map((item, index) => 
                      <div className="row so-calendar-item-container" key={'co' + index}>
                        <h5 className="rdv-type-name">{ item.event_type }</h5>
                          {/* <div className="col-md-4">
                              <div className="v_event_date"> 
                                  {item?.dates.map((date, ind) => 
                                      <div key={'event-daty-' + ind} className="event-date-item"> { formatDate(date.date) } </div>
                                  )}
                              </div>
                          </div>
                          <div className="col-md-8 col-vertical-align-item">
                              <div className="v_event_infos">
                                  <div className="v_event_type">
                                      { item.event_type }
                                  </div>
                                  <div className="v_event_date_horaire">
                                      { `De ${item.event_start_time} à ${item.event_end_time}` }
                                  </div>
                              </div>
                          </div> */}
                          <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                      </div>
                  )
                  
              }
              
          </div>
        </div>
      )
    } 

    onRenderPrivateConstructionProjetView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
              {this.state.countComingPrivateConstructionProjet === 0 ?
                  <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                          <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                          />
                      </div>
                      <div className="col-md-10 empty-textual-col">
                          <p><strong>Désolé! Aucune séance d'aide à la construction de projet n'est prévue pour le moment.</strong></p>
                      </div>
                  </div> :
                  this.state.comingPrivateConstructionProjet.map((item, index) => 
                      <div className="row so-calendar-item-container" key={'co' + index}>
                        <h5 className="rdv-type-name">{ item.event_type }</h5>
                          <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                      </div>
                  )
                  
              }
              
          </div>
        </div>
      )
    } 

    onRenderOrientationIndivView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
              {this.state.countComingSoonOrientationIndiv === 0 ?
                  <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                          <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                          />
                      </div>
                      <div className="col-md-10 empty-textual-col">
                          <p><strong>Désolé! Aucune séance d'entretien individuel d'orientation n'est prévue pour le moment.</strong></p>
                      </div>
                  </div> :
                  this.state.comingSoonOrientationIndiv.map((item, index) => 
                      <div className="row so-calendar-item-container" key={'co' + index}>
                        <h5 className="rdv-type-name">{ item.event_type }</h5>
                          <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                      </div>
                  )
                  
              }
              
          </div>
        </div>
      )
    } 

    onRenderSeanceInformationView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
              {this.state.countComingInformations === 0 ?
                  <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                          <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                          />
                      </div>
                      <div className="col-md-10 empty-textual-col">
                          <p><strong>Désolé! Aucune session collective d'information n'est prévue pour le moment.</strong></p>
                      </div>
                  </div> :
                  this.state.comingInformations.map((item, index) => 
                      <div className="row so-calendar-item-container" key={'coinfo' + index}>
                          {/* <div className="col-md-4">
                              <div className="v_event_date"> 
                                  {item?.dates.map((date, ind) => 
                                      <div key={'event-daty-' + ind} className="event-date-item"> { formatDate(date.date) } </div>
                                  )}
                              </div>
                          </div>
                          <div className="col-md-8 col-vertical-align-item">
                              <div className="v_event_infos">
                                  <div className="v_event_type">
                                      { item.event_type }
                                  </div>
                                  <div className="v_event_date_horaire">
                                      { `De ${item.event_start_time} à ${item.event_end_time}` }
                                  </div>
                              </div>
                          </div> */}
                          <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                      </div>
                  )
                  
              }
              
          </div>
        </div>
      )
    } 

    onRenderSeanceRemplissageView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
              {this.state.countComingSeanceDeRemplissages === 0 ?
                  <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                          <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                          />
                      </div>
                      <div className="col-md-10 empty-textual-col">
                          <p><strong>Désolé! Aucune séance d'aide au remplissage n'est prévue pour le moment.</strong></p>
                      </div>
                  </div>:
                  this.state.comingSeanceDeRemplissages.map((item, index) => 
                      <div className="row so-calendar-item-container" key={'co' + index}>
                          {/* <div className="col-md-4">
                              <div className="v_event_date"> 
                                  {item?.dates.map((date, ind) => 
                                      <div key={'event-daty-' + ind} className="event-date-item"> { formatDate(date.date) } </div>
                                  )}
                              </div>
                          </div>
                          <div className="col-md-8 col-vertical-align-item">
                              <div className="v_event_infos">
                                  <div className="v_event_type">
                                      { item.event_type }
                                  </div>
                                  <div className="v_event_date_horaire">
                                      { `De ${item.event_start_time} à ${item.event_end_time}` }
                                  </div>
                              </div>
                          </div> */}
                          <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                      </div>
                  )
                  
              }
              
          </div>
        </div>
          
      )
    }

    onRenderComplementDossierView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
              {this.state.countComingSoonComplementDossier === 0 ?
                  <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                          <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                          />
                      </div>
                      <div className="col-md-10 empty-textual-col">
                          <p><strong>Désolé! Aucune session collective d'information n'est prévue pour le moment.</strong></p>
                      </div>
                  </div> :
                  this.state.comingSoonComplementDossier.map((item, index) => 
                      item.dates.length !== 0 &&
                      <div className="row so-calendar-item-container" key={'coi' + index}>
                          {/* <div className="col-md-4">
                              <div className="v_event_date"> 
                                  {item?.dates.map((date, ind) => 
                                      <div key={'event-daty-' + ind} className="event-date-item"> { formatDate(date.date) } </div>
                                  )}
                              </div>
                          </div>
                          <div className="col-md-8 col-vertical-align-item">
                              <div className="v_event_infos">
                                  <div className="v_event_type">
                                      { item.event_type }
                                  </div>
                                  <div className="v_event_date_horaire">
                                      { `De ${item.event_start_time} à ${item.event_end_time}` }
                                  </div>
                              </div>
                          </div> */}
                          <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                      </div>
                       
                  )
                  
              }
              
          </div>
      </div>
      )
    } 
    
    onRenderPreparationDossierView = () => {
      return(
        <div className="row so-row">
          <div className="col-md-12 so-col calendar-col">
                {this.state.countComingPreparationDepart === 0 ?
                    <div className="row empty-alert-row">
                        <div className="col-md-2 empty-icon-col">
                            <FeatherIcon
                                icon="alert-circle"
                                className="m-b-3 empty-alert-icon rotateIn"
                            />
                        </div>
                        <div className="col-md-10 empty-textual-col">
                            <p><strong>Désolé! Aucune session collective d'information n'est prévue pour le moment.</strong></p>
                        </div>
                    </div> :
                    this.state.comingPreparationDepart.map((item, index) => 
                        <div className="row so-calendar-item-container" key={'coi' + index}>
                            {/* <div className="col-md-4">
                                <div className="v_event_date"> 
                                  {item?.dates.map((date, ind) => 
                                      <div key={'event-daty-' + ind} className="event-date-item"> { formatDate(date.date) } </div>
                                  )}
                                </div>
                            </div>
                            <div className="col-md-8 col-vertical-align-item">
                              <div className="v_event_infos">
                                  <div className="v_event_type">
                                      { item.event_type }
                                  </div>
                                  <div className="v_event_date_horaire">
                                      { `De ${item.event_start_time} à ${item.event_end_time}` }
                                  </div>
                              </div>
                            </div> */}
                            <div className="col-md-12 rdv-col-container">
                              <div className="v_event_checkout_btn_container"> 
                                <div className="row gl-checkout-action-btn-row">
                                  {
                                      item?.dates?.map((it, ind) =>
                                        <div className="col-md-6 gl-checkout-btn-col-container">
                                            {
                                              (parseInt(it?.participants) < parseInt(item.event_participant_count)) ?
                                                <button 
                                                  key={`admin-checkout-visiteur-${ind}`} 
                                                  onClick={(e) => this.onSubmitGLink(e, item, it)} 
                                                  className="btn btn-primary checkout-btn" 
                                                  data-toggle="modal" 
                                                  data-target=".checkout-event-modal"
                                                  disabled={this.state.submitGLinkInProgress ? true : false}
                                                >
                                                  <div className="rdv-location"> { item?.ville?.name } </div>
                                                  <div className="rdv-date-and-hours">
                                                    <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                    <span className="daty"> 
                                                      { moment(it.date ).format('DD-MM-YYYY')} { `De ${item.event_start_time} à ${item.event_end_time}` } 
                                                    </span>
                                                  </div>
                                                  <span className="gl-remaining"> { parseInt(item.event_participant_count) - parseInt(it?.participants) } place(s) </span>
                                                </button> :
                                                <button className="btn btn-primary checkout-btn background-opacity">
                                                  <FeatherIcon icon="check-circle" className="checkout-icon" />&nbsp;
                                                  <span className="daty"> { moment(it.date ).format('DD-MM-YYYY')} </span>
                                                </button>
                                            }
                                        </div>
                                      )
                                  }
                                </div>
                                  
                              </div>
                          </div>
                        </div>
                    )
                    
                }
                
            </div>
        </div>
      )
    } 

    onGetSeancePreparationDepart = () => {
      

      axios.get(`${ApiBaseUrl}coming-preparation-depart-france`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingPreparationDepart: response.data.data,
                      countComingPreparationDepart: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                      isEmptyComingPreparationDepart: true
                  })
              }
          })
    }

    onGetSeanceComplementDossier = () => {
      

      axios.get(`${ApiBaseUrl}coming-complement-dossier`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingSoonComplementDossier: response.data.data,
                      countComingSoonComplementDossier: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                      isEmptyComingSoonComplementDossier: true
                  })
              }
          })
    } 

    onGetSeanceOrientationIndividuellePublic = () => {
      

      axios.get(`${ApiBaseUrl}coming-public-orientation-individuelle`)
          .then(response => {
              if(response.data.success && !response.data.empty){
                  this.setState({
                      comingSoonOrientationIndiv: response.data.data,
                      countComingSoonOrientationIndiv: response.data.count
                  })
              }
              if(response.data.success && response.data.empty){
                  this.setState({
                      isEmptyComingSoonOrientationIndiv: true
                  })
              }
          })
    }

    onGetChatStatus = () => {
      

      axios.get(`${ApiBaseUrl}chat_mon_espace_configs/${1}`)
          .then(response => {
            // console.log(response)
            if(response.data.success && response.data.is_active_chat){
                this.setState({
                    isActiveChat: true
                })
            }else if(response.data.success && !response.data.is_active_chat){
                this.setState({
                    isActiveChat: false
                })
            }
          })
          .catch(error => {
              console.log(error)
          })
    }


    onCreateTalkContext = () => {
      Talk.ready
        .then(() => {
            var user = this.state.currentUser
            console.log(user)

            var userInfos = { // For chatroom
              id: user.id,
              name: user.name,
              email: user.email,
              role: "messager",
              photoUrl: user.photoUrl,
              //photoUrl: user.photoUrl,
              // signature: user.signature,
              info: user.info
            }
            const me = new Talk.User(userInfos);
            const appId = talkjsID;

            //console.log(this.state.userAvatar)
  
            if (!window.talkSession) {
                window.talkSession = new Talk.Session({
                    appId: appId,
                    me: me,
                    signature: this.state.currentUser.signature
                });
            }
            //console.log(this.state.currentUser)
            this.inbox = window.talkSession.createInbox({
              showMobileBackButton: true,
              feedConversationTitleMode: "subject",
              chatTitleMode: "subject"
            });

            this.inbox.mount(this.container)
            this.setState({
              isPageLoading: false
            })
            
            window.talkSession.unreads.on("change", function (conversationIds) {
              var unreadCount = conversationIds.length;
              if (unreadCount > 0) {
                document.title = "(" + unreadCount + " message(s) non lus) | SalonSN";
              } else {
                document.title = "Mon Espace SN | Campus France SN";
              }
              //document.getElementById("unread-message-count").innerHTML = unreadCount;
              console.log("Conversation ID's", conversationIds);
            });
  
        })
        .catch(e => console.error(e));
    }

    onGetEnabledOrDisableChat = (e,status) => {
      e.preventDefault()
      // console.log('ok')
      
      var config = this.state.requestConfig
      var isProcess = false

      if(status === 'enabled'){
        if(window.confirm('Voulez-vous vraiment activé le chat ?') === true){
          isProcess = true
        }
      }else{
        if(window.confirm('Voulez-vous vraiment désactivé le chat ?') === true){
          isProcess = true
        }
      }

      if(isProcess === true){
        this.setState({
          updateChatStatusInProgress: true
        })
        axios.get(`${ApiBaseUrl}enable-disable-chat-status/${1}/${status}`, config)
          .then(response => {
            this.setState({
              updateChatStatusInProgress: false
            })
            console.log(response)
            if(response.data.success){
              if(status === 'enable'){
                toast.success('Le Chat a été activé avec succès.')
              }else{
                toast.success('Le Chat a été désactivé avec succès.')
              }
              this.onGetChatStatus()
              this.setState({
                isSuccessUpdateChat: true
              })
              setTimeout(() => {
                this.setState({
                  isSuccessUpdateChat: false
                })
              }, 5000);
            }else{
              toast.success('Oupsss! Une erreur est survenue lors du changement de statut. Veuillez réessayer plus tard.')
              console.log(response)
              this.setState({
                isErrorUpdateChat: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorUpdateChat: false
                })
              }, 5000);
            }
          })
          .catch(error => {
            toast.success('Oupsss! Une erreur est survenue lors du changement de statut. Veuillez réessayer plus tard.')
            console.log(error)
              this.setState({
                isErrorUpdateChat: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorUpdateChat: false
                })
              }, 5000);
          })
      }
    }

    gLinkHandleChange = (e) => {
      
      let fields = this.state.fields
      fields['user_id'] = this.state.userID

      // Séance de préparation au séjour en France
      // Rendez-vous de complément de dossier

      fields[e.target.name] = e.target.value

      if(fields['event_type'] === 'Orientation Collective'){
        this.setState({
          isShowOrientationList: true,
          isShowRemplissageList: false,
          isShowInformationList: false,
          isShowPreparationList: false,
          isShowComplementList: false,
          isShowCOnstructionProjetList: false,
          isShowOrientationIndiv: false
        })
      }

      if(fields['event_type'] === 'Rendez-vous individuel d\'informations'){
        this.setState({
          isShowInformationList: true,
          isShowRemplissageList: false,
          isShowOrientationList: false,
          isShowPreparationList: false,
          isShowComplementList: false,
          isShowCOnstructionProjetList: false,
          isShowOrientationIndiv: false
        })
      }

      if(fields['event_type'] === 'Aide au remplissage'){
        this.setState({
          isShowOrientationList: false,
          isShowRemplissageList: true,
          isShowInformationList: false,
          isShowPreparationList: false,
          isShowComplementList: false,
          isShowCOnstructionProjetList: false,
          isShowOrientationIndiv: false
        })
      }

      if(fields['event_type'] === 'Séance de préparation au séjour en France'){
        this.setState({
          isShowOrientationList: false,
          isShowRemplissageList: false,
          isShowInformationList: false,
          isShowPreparationList: true,
          isShowComplementList: false,
          isShowCOnstructionProjetList: false,
          isShowOrientationIndiv: false
        })
      }

      if(fields['event_type'] === 'Rendez-vous de complément de dossier'){
        this.setState({
          isShowOrientationList: false,
          isShowRemplissageList: false,
          isShowInformationList: false,
          isShowPreparationList: false,
          isShowComplementList: true,
          isShowCOnstructionProjetList: false,
          isShowOrientationIndiv: false
        })
      }

      if(fields['event_type'] === 'Aide à la construction du projet'){
        this.setState({
          isShowOrientationList: false,
          isShowRemplissageList: false,
          isShowInformationList: false,
          isShowPreparationList: false,
          isShowComplementList: false,
          isShowCOnstructionProjetList: true,
          isShowOrientationIndiv: false
        })
      } 

      if(fields['event_type'] === 'Entretien Individuel d\'orientation'){
        this.setState({
          isShowOrientationList: false,
          isShowRemplissageList: false,
          isShowInformationList: false,
          isShowPreparationList: false,
          isShowComplementList: false,
          isShowCOnstructionProjetList: false,
          isShowOrientationIndiv: true
        })
      }

      this.setState({
        fields
      })

      console.log(fields)
    }

    onValidateGLinkForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['glink_email']){
        isValidForm = false
        this.setState({
          is_empty_glink_email: true
        })

        setTimeout(() => {
            this.setState({
              is_empty_glink_email: false
            })
        }, 5000);

        errors['glink_email'] = 'Ce champ est obligatoire'
      }

      if (typeof fields["glink_email"] !== "undefined") {
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (!pattern.test(fields["glink_email"])) {
            isValidForm = false;
            this.setState({
              is_invalid_gLink_email: true,
            });
            setTimeout(() => {
              this.setState({
                is_invalid_gLink_email: false, 
              });
            }, 5000);
    
            errors["invalid_gLink_email"] = "Votre adresse email n'est pas valide";
          }
      }

      this.setState({
        errors
      })

      return isValidForm;
    }

    onSubmitGLink = (e, eventInfos, dateItem) => {
      e.preventDefault()
      // console.log('date item', dateItem)
      if(this.onValidateGLinkForm()){
        $('.hiddenable' + eventInfos.id).css('display', 'none')
        $('.showable' + eventInfos.id).css('display', 'block')
        
        var config = this.state.requestConfig
        var data = this.state.fields
        data['event_id'] = eventInfos.id
        data['event_date_id'] = dateItem.id

        this.setState({
          submitGLinkInProgress: true
        })

        console.log(data)

        if(this.state.isShowOrientationList){
          data['event_type'] = 'Orientation Collective'
        }
        if(this.state.isShowRemplissageList){
          data['event_type'] = 'Aide au remplissage'
        }

        if(this.state.isShowInformationList){
          data['event_type'] = 'Rendez-vous individuel d\'informations'
        }

        if(this.state.isShowComplementList){
          data['event_type'] = 'Rendez-vous de complément de dossier'
        }

        if(this.state.isShowPreparationList){
          data['event_type'] = 'Séance de préparation au séjour en France'
        }

        if(this.state.isShowCOnstructionProjetList){
          data['event_type'] = 'Aide à la construction du projet'
        }
        
        axios.post(`${ApiBaseUrl}event_subscribe_link_generates`, data, config)
          .then(response => {
            this.setState({
              submitGLinkInProgress: false
            })
            if(response.data.success && !response.data.limit_reached){
              // toast.success('Le lien d\'inscription a été créé avec succès.', { position: "top-right", autoClose: 6000 })
              $('.hiddenable' + eventInfos.id).css('display', 'block')
              $('.showable' + eventInfos.id).css('display', 'none')
              this.setState({
                showFormAfterLinkCreatedSuccessfully: true,
                isCreateLinkSuccess: true,
                generatedLink: response.data.data.generated_url,
                isLinkCreated: true,
                isShowOrientationList: false,
                isShowRemplissageList: false,
                fields: {},
                errors: {}
              })
              setTimeout(() => {
                this.setState({
                  isCreateLinkSuccess: false
                })
              }, 6000);
            }else if(response.data.success && response.data.limit_reached){
              // toast.error('Oupss! Impossible de générer un lien d\'inscription car la limite de participant a été atteinte.', { position: "top-right", autoClose: 6000 })
              $('.hiddenable' + eventInfos.id).css('display', 'block')
              $('.showable' + eventInfos.id).css('display', 'none')
              this.onGetSeanceDeRemplissage()
              this.onGetOrientations()
              this.setState({
                isCreateLinkErrorLimitReached: true
              })

              setTimeout(() => {
                this.setState({
                  isCreateLinkErrorLimitReached: false
                })
              }, 6000);
              
            }
          })
          .catch(error => {
            console.log(error)
            $('.hiddenable' + eventInfos.id).css('display', 'block')
            $('.showable' + eventInfos.id).css('display', 'none')
            this.setState({
              isCreateLinkError: true,
              submitGLinkInProgress: false
            })

            setTimeout(() => {
              this.setState({
                isCreateLinkError: false
              })
            }, 6000);
          })
      }
    }

    onReturnToGenerateForm = (e) => {
      e.preventDefault()
      $('#glink_email').val('')
      $('#event_type').val('--Choisir un type de rencontre--')
      this.setState({
        showFormAfterLinkCreatedSuccessfully: false,
        isShowOrientationList: false,
        isShowInformationList: false,
        isShowRemplissageList: false,
        isShowPreparationList: false,
        isShowComplementList: false
      })
    }

    onClickToCopy = (copied) => {
      if(copy(copied)){
        this.setState({
          isSuccessCopied: true
        })
      }
     
      setTimeout(() => {
        this.setState({
          isSuccessCopied: false
        })
      }, 5000);
    }

    render() {
      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        return(
          <div className="component-admin-and-conseiller-dashboard">
            {this.state.isSuccessCopied &&
              <CopyNotification />
            }
            
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <section id="cd_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="row sh-row">
                      <div className="col-md-3 sh-col clock-col">
                        <h3 className="timezone-title"> Heure de Paris </h3>
                        <LocalClock timezone="paris" />
                      </div>
                      <div className="col-md-6 sh-col page-title-col">
                        <div className="logo-soushaulle">
                          <p className="capitalise mb-0 bold color-white mb-3">Messagerie { this.state.userRole === 'admin' ? 'Admin' : 'Conseiller' } </p>
                        </div>
                      </div>
                      <div className="col-md-3 sh-col clock-col">
                        <h3 className="timezone-title"> Heure de Dakar </h3>
                        <LocalClock timezone="dakar" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

              </div>
              

              <div className="row sv-row">
                <div className="nav-container nav-dashboard-color">
                    <NavVertical />
                </div>
                <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
                  {(this.state.userRole === 'admin' || this.state.userRole === 'conseiller-campus-france') ?
                    <>
                      {this.state.isActiveChat === true ?
                        <div className="col-md-6 offset-md-3 active_chat_container">
                          <h2 className="chat_label_status">Le CHAT est actif</h2>
                          <button className="linkable disable_chat_link" onClick={(e) => this.onGetEnabledOrDisableChat(e, 'disable')}>
                            {this.state.updateChatStatusInProgress ?
                              <>
                                Changement de status en cours &nbsp;
                                <i className="fas fa-spin fa-spinner"></i>
                              </> :
                              <>
                                <FeatherIcon icon="toggle-left" className="toggle-left-icon" />&nbsp;
                                <span className="chat_status_text">Désactiver le CHAT</span>
                              </>
                            }
                          </button>
                        </div> :
                        <div className="col-md-6 offset-md-3 active_chat_container">
                          <h2 className="chat_label_status">Le CHAT est désactivé</h2>
                          <button className="linkable active_chat_link" onClick={(e) => this.onGetEnabledOrDisableChat(e, 'enable')}>
                            {this.state.updateChatStatusInProgress ?
                              <>
                                Changement de status en cours &nbsp;
                                <i className="fas fa-spin fa-spinner"></i>
                              </> :
                              <>
                                <FeatherIcon icon="toggle-right" className="toggle-right-icon" />&nbsp;
                                <span className="chat_status_text">Activer le CHAT</span>
                              </>
                            }
                          </button>
                        </div> }
                    </> :

                    <>
                      {this.state.isActiveChat === true ?
                        <div className="col-md-6 offset-md-3 active_chat_container">
                          <div className="alert alert-success conseiller-alert-success">
                            Le Chat est actuellement ouvert.
                          </div>
                        </div> :
                        <div className="col-md-6 offset-md-3 active_chat_container">
                          <div className="alert alert-danger conseiller-alert-danger">
                            Le Chat est actuellement fermé.
                          </div>
                        </div>}
                    </>
                  }
                  
                  <div className="ligne mt-3 mb-5"></div>

                  <section id="generate-link-section">
                    <h2 className="section-title">Générer un lien d'inscription</h2>
                    <div className="row">
                      <div className="col-md-6 offset-md-3 glink-modal-btn-container">
                        <button className="btn btn-primary glink-modal-btn" data-toggle="modal" data-target=".glink-modal">
                          <FeatherIcon icon="link-2" className="glink-icon" />
                        </button>
                      </div>
                    </div>
                  </section>

                  <div className="col-md-12 rv-col">
                    <Fragment>
                      <div style={{height: '500px'}} className="conseiller-inbox-container" ref={c => this.container = c}>Loading...</div>
                    </Fragment>
                  </div>
                </div>
              </div>

              {/* {this.state.userRole === 'admin' ?
                <AdminRightSidebar isAdmin={true} /> :
                <AdminRightSidebar isAdmin={false} />
              } */}

              {this.state.isSuccessUpdateChat || this.state.isErrorUpdateChat ?
                <ToastContainer /> : ''
              }
              
            </section>

            <div className="modal fade glink-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Générer un lien de prise de RDV</h5>
                    <button type="button" className="close glink-close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    {this.state.showFormAfterLinkCreatedSuccessfully &&
                      <div className="row alert-row">
                        {this.state.isCreateLinkSuccess &&
                          <div className="col-md-12 oe-alert-container">
                            <div className="alert alert-success oe-alert-success">
                              Le lien d'inscription a été créé avec succès. Un email a été envoyé au visiteur incluant l'URL générée.
                            </div>
                          </div>
                        } 

                        {this.state.isLinkCreated &&
                          <div className="col-md-12 oe-alert-container">
                            <div className="alert alert-info oe-alert-info">
                              <p> Veuillez trouver ci-aprés le lien créé et que vous devez transmettre au visiteur. </p>
                              <p className="gLink-created-container"> { this.state.generatedLink }&nbsp; 
                                <span className="click-to-copy linkable" onClick={() => this.onClickToCopy(this.state.generatedLink)}>
                                  Copier
                                </span>
                              </p>
                            </div>
                          </div>
                        }

                        <div className="row form-cb-btn-row">
                          <div className="col-md-6 offset-md-3 form-cb-btn-col">
                            <button className="btn btn-danger eo-btn-danger" onClick={(e) => this.onReturnToGenerateForm(e)}>
                              <FeatherIcon icon="corner-up-left" className="form-cb-btn-icon" />&nbsp;
                              Retournez au Formulaire
                            </button>
                          </div>
                        </div>
                      </div>
                    }

                    {!this.state.showFormAfterLinkCreatedSuccessfully &&
                      <form id="glink-form">
                        <div className="row add-event-row">
                          <div className="col-md-6 add-event-col">
                            <label htmlFor="glink_email">Email du visiteur</label>
                            <input onChange={this.gLinkHandleChange} type="text" placeholder="Email du visiteur" className="form-control add-event-form-control" name="glink_email" id="glink_email" />
                          
                            {this.state.is_empty_glink_email &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.errors.glink_email } </div>
                            } 
                            {this.state.is_invalid_gLink_email &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.errors.invalid_gLink_email } </div>
                            }
                          </div>
                          <div className="col-md-6 add-event-col">
                            <label htmlFor="event_type">Type de rencontre</label>
                            <select onChange={this.gLinkHandleChange} className="form-control add-event-form-control" name="event_type" id="event_type">
                              <option>--Choisir un type de rencontre--</option>
                              <option value="Orientation Collective"> Session d'orientation collective </option>
                              <option value="Aide au remplissage"> Atelier d'aide au remplissage </option>
                              <option value="Rendez-vous individuel d'informations"> Rendez-vous individuel d'informations </option>
                              <option value="Rendez-vous de complément de dossier"> Rendez-vous de complément de dossier </option>
                              <option value="Séance de préparation au séjour en France"> Séance de préparation au séjour en France </option>
                              <option value="Aide à la construction du projet"> Aide à la construction du projet </option>
                              <option value="Entretien Individuel d'orientation"> Entretien Individuel d'orientation </option>
                            </select>

                            {this.state.is_empty_event_type &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_type } </div>
                            }
                          </div>

                          {this.state.isCreateLinkErrorLimitReached &&
                            <div className="col-md-12 oe-alert-container">
                              <div className="alert alert-danger oe-alert-danger">
                                Oupss! Impossible de générer un lien d'inscription car la limite de participant a été atteinte.
                              </div>
                            </div>
                          }

                          {this.state.isCreateLinkError &&
                            <div className="col-md-12 oe-alert-container">
                              <div className="alert alert-danger oe-alert-danger">
                                Oupss! Une erreur est survenue lors de la création de l'URL. Veuillez réessayer plus tard.
                              </div>
                            </div>
                          }

                          <section className="conditional-data-container">
                            {this.state.isShowOrientationList &&
                              this.onRenderSeanceOrientationView()
                            }
                            {this.state.isShowRemplissageList &&
                              this.onRenderSeanceRemplissageView()
                            }
                            {this.state.isShowInformationList &&
                              this.onRenderSeanceInformationView()
                            }
                            {this.state.isShowComplementList &&
                              this.onRenderComplementDossierView()
                            }
                            {this.state.isShowPreparationList &&
                              this.onRenderPreparationDossierView()
                            }

                            {this.state.isShowCOnstructionProjetList &&
                              this.onRenderPrivateConstructionProjetView()
                            }

                            {this.state.isShowOrientationIndiv && (
                              this.onRenderOrientationIndivView()
                            )}
                            
                          </section>
                        </div>
                      </form>
                    }
                    
                  </div>

                </div>
              </div>
            </div>
          </div>
        );
      }
    }
}
