import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CookieBanner } from '@palmabit/react-cookie-law';
import BoiteReceptionMessagerie from './components/boiteReceptionMessagerie/BoiteReceptionMessagerie'
import AdminAndConseillerDashboard from './components/adminAndConseillerDashboard/AdminAndConseillerDashboardV2'
import OrientationRemplissageManager from './components/orientationRemplissageManager/OrientationRemplissageManager'
import TutorielVideoManager from './components/tutorielVideoManager/TutorielVideoManager'
import GeneratedEventLinkForm from './components/generatedEventLinkForm/GeneratedEventLinkForm'
import MonEspaceListeConseiller from './components/monEspaceListeConseiller/MonEspaceListeConseillerV2'
import ListGeneratedLinks from './components/listGeneratedLinks/ListGeneratedLinks'
import NeedHelpInfos from './components/needHelpInfos/NeedHelpInfos'
import ListeNewsScrapped from './components/listeNewsScrapped/ListeNewsScrapped'
import Configurations from './components/configurations/Configurations'
import EventSubscribersNotifications from './components/eventSubscribersNotifications/EventSubscribersNotifications';
// import InstallPWA from './InstallPWA'
import Page404 from './components/page404/Page404'
import JeMinformeManager from './components/JeMinformeManager/JeMinformeManager';
import VillesManager from './components/VillesManager/VillesManager';
import ConseillerOutilsEtudiantChatroom from './components/conseillerChatroom/conseillerOutilsEtudiantChatroom';
import LandingPageV2 from './components/landingPageV2/LandingPageV2';

import packageJson from "../package.json"; 
import { getBuildDate } from "./utils/utils"; 
import withClearCache from "./ClearCache";
import EventManager from './components/EventManager';
import NewsletterSubscriberManager from './components/NewsletterSubscriberManager';
import SliderManager from './components/SliderManager';
import ConnexionDashboardV2 from './components/connexionAdminMonEspace/ConnexionDashboardV2';
import ResetPasswordRequest from './components/connexionAdminMonEspace/ResetPasswordRequest';
import EditPassword from './components/connexionAdminMonEspace/EditPassword';
import AutoEvaluationHome from './components/autoEvaluationSuite/AutoEvaluationHome/AutoEvaluationHome';
import AutoEvaluationQuizz from './components/autoEvaluationSuite/AutoEvaluationQuizz/AutoEvaluationQuizz';
import MonProfilEtudiant from './components/autoEvaluationSuite/MonProfilEtudiant/MonProfilEtudiant';
import AutoEvaluationDataManager from './components/AutoEvaluationDataManager/AutoEvaluationDataManager';
import ProfilEtudiantViewByCF from './components/autoEvaluationSuite/ProfilEtudiantViewByCF/ProfilEtudiantViewByCF';
import CandidatPrivatePlanning from './components/CandidatPrivatePlanning/CandidatPrivatePlanning';
import RapportView from './components/autoEvaluationSuite/RapportView/RapportView';
import { Toaster } from 'react-hot-toast';



const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  console.log("Build date:", getBuildDate(packageJson.buildDate))
  

  return (
    <Router>
      <div className="router-container">
          
        {/* <InstallPWA /> */}
        
        <Switch>
          <Route exact path="/" component={ LandingPageV2 } />
          <Route path="/modal/:event_type" component={ LandingPageV2 } />
          <Route path="/modifier-mon-mot-de-passe/:authToken" component={ EditPassword } />
          <Route path="/open-chat-dashboard" component={ ConseillerOutilsEtudiantChatroom } />
          <Route path="/admin-connexion" component={ ConnexionDashboardV2 } />
          <Route path="/admin-conseiller-dashboard" component={ AdminAndConseillerDashboard } />
          <Route path="/boite-de-reception-messagerie" component={ BoiteReceptionMessagerie } />
          <Route path="/liste-des-conseillers" component={ MonEspaceListeConseiller } />
          <Route path="/seance-orientation-remplissage-manager" component={ OrientationRemplissageManager } />
          <Route path="/tutoriel-videos-manager" component={ TutorielVideoManager } />
          <Route path="/formulaire-inscription-seances-visiteur/:email/:token/:timestamp" component={ GeneratedEventLinkForm } />
          <Route path="/liste-des-liens-generees" component={ ListGeneratedLinks } />
          <Route path="/liste-des-prises-en-charge" component={ NeedHelpInfos } />
          <Route path="/articles-manager" component={ ListeNewsScrapped } />
          <Route path="/configurations" component={ Configurations } />
          <Route path="/notifications-inscription-seances" component={EventSubscribersNotifications} />
          <Route path="/je-minforme-section-manager" component={ JeMinformeManager } />
          <Route path="/gestion-des-villes" component={VillesManager} />
          <Route path="/events-manager" component={ EventManager } />
          <Route path="/newsletters-manager" component={NewsletterSubscriberManager} />
          <Route path="/sliders-manager" component={SliderManager} />
          <Route path="/mot-de-passe-oublie" component={ResetPasswordRequest} />

          <Route path="/accueil-auto-evaluation" component={ AutoEvaluationHome } />
          <Route path="/auto-evaluation-quiz" component={ AutoEvaluationQuizz } />
          <Route path="/mon-profil-etudiant/:numero_dossier" component={ MonProfilEtudiant } />
          <Route path="/gestionnaire-des-donnees-auto-evaluation" component={AutoEvaluationDataManager} />
          <Route path="/rapport-auto-evaluation-vu-par-campus-france-sn/:numero_dossier" component={ProfilEtudiantViewByCF} />
          <Route path="/planning-prive/:link_token/:link_timestamp" component={CandidatPrivatePlanning} />

          <Route path="/rapport-auto-evaluation/:numero_dossier" component={RapportView} />
          <Route component={ Page404 } />
        </Switch>

          <div className="cookies-alerty-ssn">
            <CookieBanner
                message="En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de 
                        Cookies pour vous proposer une meilleure expérience et réaliser des statistiques de visites."
                onAccept = {() => {}}
                acceptButtonText = 'Accepter'
                // onAcceptPreferences = {() => {}}
                // onAcceptStatistics = {() => {}}
                // onAcceptMarketing = {() => {}}
            />
          </div>
        </div>
      <Toaster />
    </Router>
  );
}

export default App;
