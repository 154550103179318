import React, {Component} from 'react';
import './SliderManager.scss'
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import $ from 'jquery'
import toast from 'react-hot-toast';
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import BackendBaseurl from '../../http/BackendBaseUrl';



export default class SliderManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: false,
          sliders: [],
          sliderInfos: [],
          isAddInProgress: false,
          isSuccessAdding: false,
          isErrorAdding: false,
          updateSliderInProgress: false,
          isUpdateSliderRequestError: false,
          fields: {},
          errors: {},
          editSliderInProgress: false,
          is_empty_is_active:  false,
          is_empty_image: false,
          is_image_height_error: false
        };

        this.addSliderHandleChange = this.addSliderHandleChange.bind(this);
        this.onSubmitAddSlider = this.onSubmitAddSlider.bind(this);
        this.editSliderHandleChange = this.editSliderHandleChange.bind(this);
    }

    componentDidMount = () => {
      this.onGetSliders()
    
    }

    onGetSliders = () => {
      this.setState({
        isPageLoading: true
      })
      var config = this.state.requestConfig;
  
      axios.get(ApiBaseUrl + 'sliders', config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
  
          if (response.data.success) {
            this.setState({
              sliders: response.data.data,
            })
            console.log(response.data.data)
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          this.setState({
            isPageLoading: false,
          })
          console.log(error)
        })
    }

    goBack = (e) => {
      e.preventDefault()
      this.props.history.goBack();
    }

    onShowSliders = (row, e) => {
      this.setState({
        sliderInfos: row
      })
    }

    onDeleteSlider = (e,itemID) => {
      e.preventDefault()
      if(window.confirm('Confirmez-vous la suppression de cet événement ?')){
        var config = this.state.requestConfig
        axios.delete(`${ApiBaseUrl}sliders/${itemID}`, config)
          .then(response => {
            if(response.data.success){
              this.onGetSliders()
              toast.success('Slider supprimé avec succès.',{
                duration: 5000,
                position: 'top-center',
              })
            }else{
              console.log(response)
              toast.error('Une erreur est survenue lors de la suppression.',{
                duration: 5000,
                position: 'top-center',
              })
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Une erreur est survenue lors de la suppression.',{
              duration: 5000,
              position: 'top-center',
            })
          })
      }
      
    }

    actionsFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button data-toggle="modal" data-target=".edit-slider-modal" className="action-btn oe-edit-btn color-white" onClick={(e) => this.onGetSliderInfos(e, row)}>
            <FeatherIcon icon="edit" className="edit-icon" />
          </button>

          <button className="action-btn oe-delete-btn color-white" onClick={(e) => this.onDeleteSlider(e, row.id)}>
            <FeatherIcon icon="trash" className="trash-icon" />
          </button>
          
        </div>
      )
    }

    onGetSliderInfos = (e, item) => {
      e.preventDefault()
      this.setState({
        sliderInfos: item
      })
    }


    addSliderHandleChange = (e) => {
      let fields = this.state.fields;

      if(e.target.name !== 'image'){
        fields[e.target.name] = e.target.value
      }else{
        var reader = new FileReader();
        
        //Read the contents of Image File.
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (ev) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = ev.target.result;
                    
            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                console.log('height', height)
                if(height > 250){

                }
            };

        }
        fields[e.target.name] = e.target.files[0]
      }
      
      this.setState({
        fields
      })

      console.log(fields)
    }

    onValidateAddSliderForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['image']){
        isValidForm = false
        this.setState({
          is_empty_image: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_image: false
          })
        }, 5000);
        
        errors['image'] = 'Ce champ est obligatoire'
      }
      if(!fields['is_active'] || fields['is_active'] === 'placeholder'){
        isValidForm = false
        this.setState({
          is_empty_is_active: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_active: false
          })
        }, 5000);
        
        errors['is_active'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    getFormData = object => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    
    onSubmitAddSlider = (e) => {
      e.preventDefault()
      if(this.onValidateAddSliderForm()){
        console.log('ok')
        this.setState({
          isAddInProgress: true
        })

        let data = this.state.fields;
        var config = this.state.requestConfig;
        
        let fd = new FormData();
        fd.append('slider_primary_text', data.slider_primary_text);
        fd.append('image', data.image);
        fd.append('is_active', data.is_active);
        fd.append('slider_secondary_text', data.slider_secondary_text);
        fd.append('user_id', this.state.userID);
        fd.append('action_btn_url', data?.action_btn_url)

        axios.post(`${ApiBaseUrl}sliders`, fd, config)
          .then(response => {
            this.setState({
              isAddInProgress: false
            })
            if(response.data.success){
              this.onGetSliders()
              document.getElementById('add-slider-form').reset();
              $('.close').click()
              toast.success('Slider ajouté avec succès', {
                duration: 5000,
                position: 'top-center',
                style: {},
                className: '',
                icon: '👏',
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
                duration: 5000,
                position: 'top-center',
                style: {},
                className: '',
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              isAddInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              style: {},
              className: '',
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }
    }

    editSliderHandleChange = (e) => {
      e.persist()
      var name = e.target.name
      var value = e.target.name !== 'image' ? e.target.value : e.target.files[0];

      if(e.target.name === 'image'){
        // eslint-disable-next-line
        this.state.sliderInfos.image = e.target.files[0];
      }
      // console.log(value)
      this.setState((prevState) => ({
        sliderInfos: { ...prevState.sliderInfos, [name]: value }
      }))
      console.log(this.state.sliderInfos)
    }

    onValidateEditSliderForm = () => {
      let fields = this.state.sliderInfos
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['is_active'] || fields['is_active'] === 'placeholder'){
        isValidForm = false
        this.setState({
          is_empty_is_active: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_active: false
          })
        }, 5000);
        
        errors['is_active'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitEditSLider = async (e) => {
      e.preventDefault()

      console.log(this.state.sliderInfos)

      if(this.onValidateEditSliderForm()){
        this.setState({
          editSliderInProgress: true
        })

        let data = this.state.sliderInfos;
        let fd = new FormData();
        fd.append('slider_primary_text', data.slider_primary_text);
        fd.append('image', data.image);
        fd.append('is_active', data.is_active);
        fd.append('slider_secondary_text', data.slider_secondary_text);
        fd.append('user_id', this.state.userID);
        fd.append('action_btn_url', data?.action_btn_url)

        for (const value of fd.values()) {
          console.log(value);
        }

        await axios.put(`${ApiBaseUrl}sliders/${data.id}`, fd)
          .then(response => {
            this.setState({
              editSliderInProgress: false
            })
            if(response.data.success){
              this.onGetSliders()
              $('.close').click()
              this.setState({
                sliderInfos: []
              })
              toast.success('Slider modifié avec succès', {
                duration: 5000,
                position: 'top-center',
                style: {},
                className: '',
                icon: '👏',
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de la modification.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              editSliderInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              style: {},
              className: '',
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }

    }

    isActiveFormatter = (cell, row) => {
      if(cell === true || row.is_active === 1 || row.is_active === '1'){
        return <span className="badge badge-success">actif</span>
      }
      return <span className="badge badge-danger">inactif</span>
    }

    LinkFormatter = (cell, row) => {
      return <a rel="noopener noreferrer" href={cell} target="_blank" className="event_link_deco">Ouvrir le lien</a>
    }

    ImageFormatter = (cell, row) => {
      return <img alt="Event Deco" width={'100%'} height={'100%'} className="event_img_deco" src={ BackendBaseurl + '/' + cell } />
    }


    render() {
      const options = {
        noDataText: 'Aucun article trouvé.'
        // withoutNoDataText: true, // this will make the noDataText hidden, means only showing the table header
      };

      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        return(
          <div className="component-liste-conseillers-oe">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3">Gestion du Slider</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                    <div className="row page-content-row">
                      <div className="col-md-10 offset-md-1 orm-add-btn-col">
                        <button className="orm-add-btn btn btn-primary scrapping-siblings" data-toggle="modal" data-target=".add-slider-modal">
                          <FeatherIcon icon="plus" className="add-plus-icon" />
                        </button>
                      </div>

                      <div className="col-md-12 cm-col oe-liste-conseiller-table-container">
                        <BootstrapTable data={this.state.sliders} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>News ID</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.ImageFormatter.bind(this)}
                              dataField='slider_image'>
                              Image</TableHeaderColumn>
                            <TableHeaderColumn
                              dataField='slider_primary_text'>
                              Texte primaire</TableHeaderColumn>
                            <TableHeaderColumn
                              dataField='slider_secondary_text'>
                              Texte primaire</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.isActiveFormatter.bind(this)}
                              dataField='is_active'>
                              Statut</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.actionsFormatter.bind(this)} dataField='id'>Actions</TableHeaderColumn>
                        </BootstrapTable>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Add Event modal */}
            <div className="modal fade add-slider-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter un Slider</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form id="add-slider-form">
                      <div className="row add-event-row">
                        <div className="col-md-12 add-event-col">
                          <label htmlFor="slider_primary_text">Texte Primaire</label>
                          <input onChange={this.addSliderHandleChange} type="text" placeholder="Texte primaire" className="form-control add-event-form-control" name="slider_primary_text" id="slider_primary_text" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="slider_secondary_text">Text Secondaire</label>
                          <input onChange={this.addSliderHandleChange} type="text" placeholder="Texte secondaire" className="form-control add-event-form-control" name="slider_secondary_text" id="slider_secondary_text" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="action_btn_url">Lien du bouton d'action</label>
                          <input onChange={this.addSliderHandleChange} type="text" placeholder="Lien du bouton d'action'" className="form-control add-event-form-control" name="action_btn_url" id="action_btn_url" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="is_active">Statut</label>
                          <select onChange={this.addSliderHandleChange} type="text" placeholder="Statut de publication de l'événement" className="form-control add-event-form-control" name="is_active" id="is_active">
                            <option value="placeholder">--Choisir--</option>
                            <option value={1} >Publier en ligne</option>
                            <option value={0}>Ne pas publier</option>
                          </select>
                        
                          {this.state.is_empty_is_active &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_active } </div>
                          }
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="image">Image du Slide  </label>
                          <input onChange={this.addSliderHandleChange} type="file" className="form-control add-event-form-control" name="image" id="image" />
                          {/* <span className="size-alert">Uploader de préférence une image de taille 1440x250</span> */}
                          {this.state.is_empty_image &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.image } </div>
                          } 
                        </div>

                        <div className="col-md-12 add-event-submit-col">
                          {!this.state.isAddInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddSlider(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div className="modal fade edit-slider-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Modifier un Slider</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        
                      <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_slider_primary_text">Texte Primaire</label>
                          <input value={this.state.sliderInfos?.slider_primary_text} onChange={this.editSliderHandleChange} type="text" placeholder="Texte Primaire" className="form-control add-event-form-control" name="slider_primary_text" id="edit_slider_primary_text" />
                        </div> 

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_slider_secondary_text">Texte Secondaire</label>
                          <input value={this.state.sliderInfos?.slider_secondary_text} onChange={this.editSliderHandleChange} type="text" placeholder="Texte Secondaire" className="form-control add-event-form-control" name="slider_secondary_text" id="edit_slider_secondary_text" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_action_btn_url">Lien du bouton d'action</label>
                          <input value={this.state.sliderInfos?.action_btn_url} onChange={this.editSliderHandleChange} type="text" placeholder="Lien du bouton d'action" className="form-control add-event-form-control" name="action_btn_url" id="edit_action_btn_url" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_is_active">Statut</label>
                          <select onChange={this.editSliderHandleChange} type="text" className="form-control add-event-form-control" name="is_active" id="edit_is_active">
                            <option value="placeholder">--Choisir--</option>
                            <option selected={(this.state.sliderInfos?.is_active === '1' || this.state.sliderInfos?.is_active === 1 || this.state.sliderInfos?.is_active === true) && true} value={1} >Publier en ligne</option>
                            <option selected={(this.state.sliderInfos?.is_active === '0' || this.state.sliderInfos?.is_active === 0 || this.state.sliderInfos?.is_active === false) && true} value={0}>Ne pas publier</option>
                          </select>
                        
                          {this.state.is_empty_is_active &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_active } </div>
                          }
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_image">Image du slide</label>
                          <input onChange={this.editSliderHandleChange} type="file" className="form-control add-event-form-control" name="image" id="edit_image" />
                          <span className="size-alert">Uploader de préférence une image de taille 1280x728</span>
                          {this.state.is_empty_image &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.image } </div>
                          }
                        </div>

                        <div className="col-md-12 add-event-submit-col">
                          {!this.state.editSliderInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitEditSLider(e) }>
                              <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            
          </div>
        )
      }
    }
  }