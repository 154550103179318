import React, {Component} from 'react';
import './NeedHelpInfos.scss';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import formatDate from '../../utils'
import jsPDF from "jspdf";
import "jspdf-autotable";
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import { ExportToExcel } from '../modules/ExportToExcel/ExportToExcel';


export default class NeedHelpInfos extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          needHelpList: [],
          isPageLoading: true,
          needHelpCount: 0,
          needHelpItemInfos: [],

        };
    }

    componentDidMount = () => {
      this.onGetNeedHelpInfos()
    }

    goBack = (e) => {
      e.preventDefault()
      this.props.history.goBack();
    }

    onGetNeedHelpInfos = () => {
      var config = this.state.requestConfig

      axios.get(`${ApiBaseUrl}need_help_visitors`, config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success){
            this.setState({
              needHelpList: response.data.data,
              needHelpCount: response.data.count
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
        })
    }

    actionsFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button onClick={() => this.onGetNeedHelpItemInfos(row)} className="btn btn-primary action-btn show-btn" data-toggle="modal" data-target=".show-visitor-needs">
            <FeatherIcon icon="eye" className="action-icon" />
          </button>
        </div>
      )
    }

    onGetNeedHelpItemInfos = (item) => {
      this.setState({
        needHelpItemInfos: item
      })
    }

    hasAccountFormatter = (cell, row) => {
      if(cell === 1){
        return(
          <span className="badge badge-success mesp-badge-success"> OUI </span>
        )
      }else{
        return(
          <span className="badge badge-danger mesp-badge-danger"> NON </span>
        )
      }
    }

    accountNumberFormatter = (cell, row) => {
      if(cell !== null){
        return(
          <span className="account_number_"> { cell } </span>
        )
      }
    }

    createdAtFormatter = (cell, row) => {
      return(
        <span className="created_at_content">
          { formatDate(cell) }
        </span>
      )
    }

    onExportToPDF = (exportableData) => {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape
  
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
  
      doc.setFontSize(15);
  
      const title = "Liste des visiteurs pris en charge";
      const headers = [["Date","Besoins","Prénom & Nom", "Email", "Téléphone", "Diplôme", "Bachelier (Série)", "Numéro compte FR"]];
  
      const data = exportableData.map(elt=> [elt.created_at, elt.visitor_needs, elt.visitor_fullname, elt.visitor_email, elt.visitor_phone, elt.visitor_degree, elt.visitor_serie, elt.visitor_account_number]);
  
      let content = {
        startY: 50,
        head: headers,
        body: data
      };

      const pdfName = "Export_Liste_Visiteur_Pris_En_Charge" 
  
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(`${pdfName}.pdf`)
    }

    exportExcelFormatter = (dataToExport) => {
      const exportInfos = [
        {label: "Date de prise en charge", value: "created_at"},
        {label: "Besoins", value: "visitor_needs"},
        {label: "Prénom & Nom", value: "visitor_fullname"},
        {label: "Email", value: "visitor_email"},
        {label: "Téléphone", value: "visitor_phone"},
        {label: "Diplôme", value: "visitor_degree"},
        {label: "Bachelier (Série)", value: "visitor_serie"},
        {label: "Compte FR", value: "visitor_has_account"},
        {label: "Numéro compte FR", value: "visitor_account_number"}
      ];

      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let currentDate = `${day}-${month}-${year}`;

      return (
        <div className="export-to-xlsx-container linkable">
          <ExportToExcel
            isPriseEnCharge={true}
            exportData={dataToExport}
            exportLabels={exportInfos} 
            date={currentDate} 
          />
        </div> 
      )
    }


    render() {
      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        const options = {
          noDataText: 'Aucun lien trouvé.'
        };
        return(
          <div className="component-need-help-infos">

            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={false} />

            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3 pt-3">Liste des visiteurs pris en charge</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                    <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                    <div className="row page-content-row">
                      <div className="col-md-12 need-help-count">
                        <span className="need-help-count-label"> Nombre de visiteurs pris en charge </span>&nbsp;
                        <span className="badge badge-primary need-help-count-badge"> { this.state.needHelpCount } </span>
                      </div>
                      {/* <div className="col-md-4 export-btn-col">
                        <button className="btn btn-primary export-pdf-btn" onClick={() => this.onExportToPDF(this.state.needHelpList)}>
                          <FeatherIcon icon="download" className="pdf-download-icon" />&nbsp;
                          Exporter en PDF
                        </button>
                      </div> */}
                      <div className="col-md-3 offset-md-5 export-excel-btn-col">
                        { this.exportExcelFormatter(this.state.needHelpList) }
                      </div>
                      <div className="col-md-12 cm-col oe-liste-liens-table-container">
                        <BootstrapTable data={this.state.needHelpList} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>ID</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.createdAtFormatter.bind(this)}
                              width="400"
                              dataField='created_at'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Prénom et Nom</TableHeaderColumn>
                            <TableHeaderColumn
                              width="400"
                              dataField='visitor_fullname'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Prénom et Nom</TableHeaderColumn>
                            <TableHeaderColumn
                              width="400"
                              dataField='visitor_email'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Email</TableHeaderColumn>
                            <TableHeaderColumn 
                              width="250"
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataField='visitor_phone'>
                              Téléphone</TableHeaderColumn>
                            <TableHeaderColumn 
                              width="150" 
                              dataField='visitor_degree'>
                              Diplôme</TableHeaderColumn>
                            {/* <TableHeaderColumn 
                              width="250" 
                              dataField='visitor_needs'>
                              Besoins</TableHeaderColumn> */}
                            <TableHeaderColumn 
                              width="150" 
                              dataField='visitor_serie'>
                              Bachelier (Série)</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataFormat={this.hasAccountFormatter.bind(this)}
                              width="150" 
                              dataField='visitor_has_account'>
                              Compte Etude FR</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataFormat={this.accountNumberFormatter.bind(this)}
                              width="150" 
                              dataField='visitor_account_number'>
                              Numéro Compte Etude FR</TableHeaderColumn>
                            <TableHeaderColumn width="100" dataFormat={this.actionsFormatter.bind(this)} dataField='id'>Actions</TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <div className="modal fade show-visitor-needs oe-modal" tabIndex="-1" role="dialog" aria-labelledby="showLinkInfosLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="showLinkInfosLabel"> {`Les besoins de ${this.state.needHelpItemInfos.visitor_fullname}`} </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <div className="modal-body">
                          <div className="row orm-row">
                            <div className="col-md-12 visitor-needs-container">
                              <p>
                                { this.state.needHelpItemInfos.visitor_needs }
                              </p>
                            </div>
                          </div>
                      </div>

                  </div>
              </div>
            </div>

          </div>
        );
      }
    }
}
