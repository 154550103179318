import React, {Component} from 'react';
import './TutorielVideoManager.scss';
import axios from 'axios';
// import { NavLink } from 'react-router-dom';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import { toast, ToastContainer } from 'react-toastify';
import {BootstrapTable, TableHeaderColumn, ClearSearchButton, SearchField, ButtonGroup} from 'react-bootstrap-table';
import $ from 'jquery'
// import formatDate from '../../utils'
import ReactPlayer from 'react-player'
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';


export default class TutorielVideoManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          userAvatar: window.sessionStorage.getItem('user_avatar'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userRole: window.sessionStorage.getItem('user_role'),
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: true,
          isSessionExpiration: false,
          videosList: [],
          fields: {},
          errors: {},
          addVideoInProgress: false,
          isSuccessAddVideo: false,
          isErrorAddVideo: false,
          is_empty_video_title: false,
          is_empty_youtube_url: false,
          is_empty_is_online: false,
          is_empty_link_type: false,
          is_empty_order: false,
          videoInfos: [],
          updateVideoInProgress: false,
          isSuccessUpdateVideo: false,
          isErrorUpdateVideo: false,

        };

        this.addVideoHandleChange = this.addVideoHandleChange.bind(this)
        this.onSubmitAddVideo = this.onSubmitAddVideo.bind(this)
        this.updateVideoHandleChange = this.updateVideoHandleChange.bind(this)
        this.onSubmitUpdateVideo = this.onSubmitUpdateVideo.bind(this)
    }

    componentDidMount(){
      this.onGetVideos()
    }

    onGetVideos = () => {
      
      var config = this.state.requestConfig

      axios.get(`${ApiBaseUrl}tutoriel_videos`, config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success){
            // console.log(response.data.data)
            this.setState({
              videosList: response.data.data
            })
          }else{
            console.log(response)
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    createCustomSearchField = (props) => {
      return (
        <SearchField
            className='search-field'
            placeholder='Rechercher'/>
      );
    }

    createCustomButtonGroup = props => {
      return (
          <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
              {props.exportCSVBtn}
          </ButtonGroup>
      );
    }

    videoYoutubeFormatter = (cell, row) => {
      return(
        <div className="youtube_video_preview">
          <ReactPlayer
            className="youtube_preview"
            url={cell}
          /> 
        </div>
      )
    }

    onlineStatusFormatter = (cell, row) => {
      if(cell === 1){
        return(
          <span className="badge badge-success orm-badge-success"> en ligne </span>
        )
      }else{
        return(
          <span className="badge badge-warning orm-badge-warning"> non publié </span>
        )
      }
    }

    onActionFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button className="btn btn-primary action-btn edit-btn" data-toggle="modal" data-target=".edit-video-modal" onClick={() => this.onGetVideoInfos(row)}>
            <FeatherIcon icon="edit" className="action-icon" />
          </button>&nbsp;
          <button className={`btn btn-primary action-btn delete-btn delete-item${cell}`} onClick={() => this.onDeleteVideo(row.id)}>
            <FeatherIcon icon="trash" className="action-icon" />
          </button>
        </div>
      )
    }

    onGetVideoInfos = (video) => {
      this.setState({
        videoInfos: video
      })
    }

    addVideoHandleChange = (e) => {
      let fields = this.state.fields
      fields['user_id'] =this.state.userID
      fields[e.target.name] = e.target.value

      this.setState({
        fields
      })

      console.log(fields)
    }

    onValidateAddVideoForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['link_type'] || fields['link_type'] === '--Choisir un type de séance--'){
        isValidForm = false
        this.setState({
          is_empty_link_type: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_link_type: false
          })
        }, 5000);
        
        errors['link_type'] = 'Ce champ est obligatoire'
      }

      if(!fields['is_online'] || fields['is_online'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_online: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_online: false
          })
        }, 5000);
        
        errors['is_online'] = 'Ce champ est obligatoire'
      }

      if(!fields['video_title']){
        isValidForm = false
        this.setState({
          is_empty_video_title: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_video_title: false
          })
        }, 5000);
        
        errors['video_title'] = 'Ce champ est obligatoire'
      }

      if(!fields['youtube_url']){
        isValidForm = false
        this.setState({
          is_empty_youtube_url: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_youtube_url: false
          })
        }, 5000);
        
        errors['youtube_url'] = 'Ce champ est obligatoire'
      }

      if(!fields['order']){
        isValidForm = false
        this.setState({
          is_empty_order: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_order: false
          })
        }, 5000);
        
        errors['order'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitAddVideo = (e) => {
      e.preventDefault()

      if(this.onValidateAddVideoForm()){
        this.setState({
          addVideoInProgress: true
        })
        
        var config = this.state.requestConfig
        var data = this.state.fields

        axios.post(`${ApiBaseUrl}tutoriel_videos`, data, config)
          .then(response => {
            this.setState({
              addVideoInProgress: false
            })

            if(response.data.success){
              this.onGetVideos()
              toast.success('La vidéo a été bien ajoutée.')
              $('#video_title').val('')
              $('#youtube_url').val('')
              $('#is_online').val('--Choisir un statut--')
              $('.close').click()
              this.setState({
                isSuccessAddVideo: true
              })
              setTimeout(() => {
                this.setState({
                  isSuccessAddVideo: false
                })
              }, 5000);
            }else{
              console.log(response)
              toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.')
              this.setState({
                isErrorAddVideo: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorAddVideo: false
                })
              }, 5000);
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.')
            this.setState({
              addVideoInProgress: false,
              isErrorAddVideo: true
            })
            setTimeout(() => {
              this.setState({
                isErrorAddVideo: false
              })
            }, 5000);

            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }

          })
      }
    }

    updateVideoHandleChange = (e) => {
      e.persist();

      this.setState(prevState => ({
        videoInfos: { ...prevState.videoInfos,  [e.target.name]: e.target.value }
      }))
    }

    onValidateUpdateVideoForm = () => {
      let fields = this.state.videoInfos
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['link_type'] || fields['link_type'] === '--Choisir un type de séance--'){
        isValidForm = false
        this.setState({
          is_empty_link_type: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_link_type: false
          })
        }, 5000);
        
        errors['link_type'] = 'Ce champ est obligatoire'
      }

      if(!fields['is_online'] || fields['is_online'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_online: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_online: false
          })
        }, 5000);
        
        errors['is_online'] = 'Ce champ est obligatoire'
      }

      if(!fields['video_title']){
        isValidForm = false
        this.setState({
          is_empty_video_title: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_video_title: false
          })
        }, 5000);
        
        errors['video_title'] = 'Ce champ est obligatoire'
      }

      if(!fields['youtube_url']){
        isValidForm = false
        this.setState({
          is_empty_youtube_url: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_youtube_url: false
          })
        }, 5000);
        
        errors['youtube_url'] = 'Ce champ est obligatoire'
      }

      if(!fields['order']){
        isValidForm = false
        this.setState({
          is_empty_order: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_order: false
          })
        }, 5000);
        
        errors['order'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitUpdateVideo = (e) => {
      e.preventDefault()

      if(this.onValidateUpdateVideoForm()){
        this.setState({
          updateVideoInProgress: true
        })
        
        var config = this.state.requestConfig
        var data = this.state.videoInfos
        // return console.log(data)
        axios.put(`${ApiBaseUrl}tutoriel_videos/${data.id}`, data, config)
          .then(response => {
            this.setState({
              updateVideoInProgress: false
            })

            if(response.data.success){
              this.onGetVideos()
              toast.success('La vidéo a été bien modifiée.')
              $('.close').click()
              this.setState({
                videoInfos: [],
                isSuccessUpdateVideo: true
              })
              setTimeout(() => {
                this.setState({
                  isSuccessUpdateVideo: false
                })
              }, 5000);
            }else{
              console.log(response)
              toast.error('Oupsss! Une erreur est survenue lors de la modification.')
              this.setState({
                isErrorUpdateVideo: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorUpdateVideo: false
                })
              }, 5000);
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de la modification.')
            this.setState({
              updateVideoInProgress: false,
              isErrorUpdateVideo: true
            })
            setTimeout(() => {
              this.setState({
                isErrorUpdateVideo: false
              })
            }, 5000);

            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }

          })
      }
    }

    onDeleteVideo = (videoID) => {
      if(window.confirm('Confirmez-vous la suppression de ce tutoriel ?')){
        
        var config = this.state.requestConfig
        $('.delete-item' + videoID).html('<i class="fas fa-spin fa-spinner"></i>')
        axios.delete(`${ApiBaseUrl}tutoriel_videos/${videoID}`, config)
          .then(response => {
            if(response.data.success){
              this.onGetVideos()
              toast.success('La vidéo a été supprimée avec succès.', { position: "top-right", autoClose: 5000 })
            }else{
              this.onGetVideos()
              console.log(response)
              toast.errors('Oupsss! Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.', { position: "top-right", autoClose: 5000 })
            }
          })
          .catch(error => {
            this.onGetVideos()
            console.log(error)
            toast.errors('Oupsss! Une erreur est survenue lors de la suppression. Veuillez réessayer plus tard.', { position: "top-right", autoClose: 5000 })
          })
      }
    }

    linkTypeFormatter = (cell, row) => {
      if(cell === null || cell === undefined){
        return(
          <span className="badge badge-danger orm-badge-danger"> non typé </span>
        )
      }else{
        return row.link_type
      }
    }

    orderFormatter = (cell, row) => {
      if(cell === null || cell === undefined){
        return(
          <span className="badge badge-danger orm-badge-danger"> non ordonné </span>
        )
      }else{
        return row.order
      }
    }

    render() {
      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {

        const options = {
          clearSearch: true,
          clearSearchBtn: this.createCustomClearButton,
          noDataText: 'Aucune donnée disponible',
          searchField: this.createCustomSearchField,
          btnGroup: this.createCustomButtonGroup
        };

        return(
          <div className="component-tutoriel-video-manager">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <section id="cd_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3"> Tutoriels Vidéos Manager </p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

              </div>

              <div className="row tvm-row">
                <div className="nav-container nav-dashboard-color">
                    <NavVertical />
                </div>
                <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0">
                  <div className="row page-content-row">
                    <div className="col-md-4 offset-md-4 orm-add-btn-col">
                      <button className="orm-add-btn btn btn-primary" data-toggle="modal" data-target=".add-video-modal">
                        <FeatherIcon icon="plus" className="add-plus-icon" />
                      </button>
                    </div>

                    <div className="col-md-10 offset-md-1 orm-table-col">
                      <div className="orm-table-container">
                        <BootstrapTable data={this.state.videosList}
                          striped={true} hover={true} condensed={true}
                          options={options}
                          // multiColumnSearch={ true }
                          pagination
                          search={true} version='4'>
                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}>Numero</TableHeaderColumn>
                            <TableHeaderColumn width="120"
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true} 
                              dataField='video_title'>Titre</TableHeaderColumn>
                            <TableHeaderColumn width="120" dataField='link_type' 
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true} 
                              dataFormat={this.linkTypeFormatter.bind(this)}>Type de séance</TableHeaderColumn>
                            <TableHeaderColumn width="90" dataField='order' 
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true} 
                              dataFormat={this.orderFormatter.bind(this)}>Ordre d'affichage</TableHeaderColumn>
                            <TableHeaderColumn width="100" dataField='youtube_url' dataFormat={this.videoYoutubeFormatter.bind(this)} >Vidéos Youtube</TableHeaderColumn>
                            <TableHeaderColumn width="90" dataField='is_online' dataFormat={this.onlineStatusFormatter.bind(this)}>Statuts</TableHeaderColumn>
                            <TableHeaderColumn width="100" dataField='id' dataFormat={this.onActionFormatter.bind(this)}>Action</TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>



            {/* Add video Modal */}
            <div className="modal fade add-video-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter un Tutoriel Vidéo</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="link_type">Choix du type de séance</label>
                          <select onChange={this.addVideoHandleChange} className="form-control add-event-form-control" name="link_type" id="link_type">
                            <option>--Choisir un type de séance--</option>
                            <option value="Orientation Collective"> Orientation collective </option>
                            <option value="Aide au remplissage"> Atelier d'aide au remplissage </option>
                            <option value="Rendez-vous individuel d'informations"> Rendez-vous individuel d'informations </option>
                            <option value="Rendez-vous de complément de dossier"> Rendez-vous de complément de dossier </option>
                            <option value="Séance de préparation au séjour en France"> Séance de préparation au séjour en France </option>
                            
                          </select>

                          {this.state.is_empty_link_type &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.link_type } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="video_title">Titre du tutoriel</label>
                          <input onChange={this.addVideoHandleChange} type="text" placeholder="Titre du tutoriel" className="form-control add-event-form-control" name="video_title" id="video_title" />
                        
                          {this.state.is_empty_video_title &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.video_title } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="youtube_url">URL Youtube</label>
                          <input onChange={this.addVideoHandleChange} type="text" placeholder="URL Youtube" className="form-control add-event-form-control" name="youtube_url" id="youtube_url" />
                        
                          {this.state.is_empty_youtube_url &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.youtube_url } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="is_online">Statut</label>
                          <select onChange={this.addVideoHandleChange} className="form-control add-event-form-control" name="is_online" id="is_online">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Publier en ligne </option>
                            <option value={0}> Ne pas publier </option>
                          </select>

                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="order">Ordre d'affichage</label>
                          <input onChange={this.addVideoHandleChange} type="number" placeholder="Définir l'ordre d'affichage" className="form-control add-event-form-control" name="order" id="order" />
                        
                          {this.state.is_empty_order &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.order } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.addVideoInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddVideo(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            {/* Update video Modal */}
            <div className="modal fade edit-video-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="UpdateVideoModalTitle" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                        <h5 className="modal-title" id="UpdateVideoModalTitle">Modifier { this.state.videoInfos.video_title } </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_link_type">Choix du type de séance</label>
                          <select value={this.state.videoInfos.link_type} onChange={this.updateVideoHandleChange} className="form-control add-event-form-control" name="link_type" id="update_link_type">
                            <option>--Choisir un type de séance--</option>
                            <option value="Orientation Collective"> Orientation collective </option>
                            <option value="Aide au remplissage"> Atelier d'aide au remplissage </option>
                            <option value="Rendez-vous individuel d'informations"> Rendez-vous individuel d'informations </option>
                            <option value="Rendez-vous de complément de dossier"> Rendez-vous de complément de dossier </option>
                            <option value="Séance de préparation au séjour en France"> Séance de préparation au séjour en France </option>
                          </select>

                          {this.state.is_empty_link_type &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.link_type } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_video_title">Titre du tutoriel</label>
                          <input value={this.state.videoInfos.video_title} onChange={this.updateVideoHandleChange} type="text" placeholder="Titre du tutoriel" className="form-control add-event-form-control" name="video_title" id="edit_update_video_title" />
                        
                          {this.state.is_empty_video_title &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.video_title } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_youtube_url">URL Youtube</label>
                          <input value={this.state.videoInfos.youtube_url} onChange={this.updateVideoHandleChange} type="text" placeholder="URL Youtube" className="form-control add-event-form-control" name="youtube_url" id="update_youtube_url" />
                        
                          {this.state.is_empty_youtube_url &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.youtube_url } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_is_online">Statut</label>
                          <select value={this.state.videoInfos.is_online} onChange={this.updateVideoHandleChange} className="form-control add-event-form-control" name="is_online" id="update_is_online">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Publier en ligne </option>
                            <option value={0}> Ne pas publier </option>
                          </select>

                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="order">Ordre d'affichage</label>
                          <input value={this.state.videoInfos.order} onChange={this.updateVideoHandleChange} type="number" placeholder="Définir l'ordre d'affichage" className="form-control add-event-form-control" name="order" id="order" />
                        
                          {this.state.is_empty_order &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.order } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.updateVideoInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitUpdateVideo(e) }>
                              <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            {this.state.isSuccessAddVideo || this.state.isErrorAddVideo || this.state.isSuccessUpdateVideo || this.state.isErrorUpdateVideo ?
              <ToastContainer /> : ''
            }
          </div>
        );
      }
    }
}
