// LOCAL
// const ApiBaseUrl = 'http://127.0.0.1:8000/api/';

// DEMO
// const ApiBaseUrl = 'https://api-monespacesn-v2.withvolkeno.com/api/';

// PROD
// const ApiBaseUrl = 'https://prod.api-monespacesn-v2.withvolkeno.com/api/';
const ApiBaseUrl = 'https://api.monespacesn.volkeno.com/api/';

export default ApiBaseUrl;