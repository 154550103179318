import React, {Component} from 'react';
import './NewsletterSubscriberManager.scss'
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import NavVertical from '../navVertical/NavVertical';
import { ExportToExcel } from '../modules/ExportToExcel/ExportToExcel';

export default class NewsletterSubscriberManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: false,
          newsletters: [],
          exportInProgress: false,
          exportInfos: [
            {label: "Email", value: "user_email"},
          ]
        };
    }

    componentDidMount = () => {
      this.onGetSubscribers()
    }

    onGetSubscribers = () => {
      this.setState({
        isPageLoading: true
      })
      var config = this.state.requestConfig;
  
      axios.get(ApiBaseUrl + 'newsletters', config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
  
          if (response.data.success) {
            this.setState({
              newsletters: response.data.data,
            })
            console.log(response.data.data)
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          this.setState({
            isPageLoading: false,
          })
          console.log(error)
        })
    }

    onExportSubscribers = () => {
      this.setState({
        exportInProgress: true
      })

      axios.get(ApiBaseUrl + 'export-newsletter-subscribers')
        .then(response => {
          this.setState({
            exportInProgress: false
          });
          
          if(response.data.success && response.data.status){
            console.log(response)
            const link = document.createElement('a');
            link.href = response.data.file;
            link.target = '_blank';
            console.log(link)
            // link.download;
            link.setAttribute(
              'download',
              response.data.file
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // fileDownload(response.data.file, 'Sadio.xlsx')
          }
        })
    }


    render() {
      const options = {
        noDataText: 'Aucun article trouvé.'
        // withoutNoDataText: true, // this will make the noDataText hidden, means only showing the table header
      };

      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        return(
          <div className="component-liste-conseillers-oe">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3">Liste des inscrits aux newsletters</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                    <div className="row page-content-row">
                      {!this.state.isPageLoading &&
                        <div className="exportable-email-newsletters linkable">
                          <ExportToExcel isRDV={false} exportData={this.state.newsletters} exportLabels={this.state.exportInfos} />
                        </div>
                      }

                      <div className="col-md-12 cm-col oe-liste-conseiller-table-container">
                        <BootstrapTable data={this.state.newsletters} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>Newsletter ID</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataField='user_email'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Email</TableHeaderColumn>
                            
                        </BootstrapTable>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
      }
    }
  }