import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import Logo from '../../img/logo-campus-france.png';
import toast from 'react-hot-toast';
import axios from 'axios';

const ResetPasswordRequest = () => {
    const [inProgress, setInProgress] = useState(false);
    const [email, setEmail] = useState();
    const [errors, setErrors] = useState(false);
    const [email_empty, set_email_empty] = useState(false);
    const [email_syntax_error, set_email_syntax_error] = useState(false);


    const onValidateResetPasswordFormData = () => {
        let _errors = errors;
        var isValidForm = true;
    
        if(!email){
            isValidForm = false;
            set_email_empty(true);
            setTimeout(() => {
                set_email_empty(false);
            }, 5000);
            _errors["email"] = 'Ce champ email est obligatoire'
        }
        if (typeof email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValidForm = false;
                set_email_syntax_error(true);
                setTimeout(() => {
                    set_email_syntax_error(false);
                }, 5000);
                _errors["email_syntax_error"] = "L'adresse email est invalide.";
            }
        }
    
        setErrors(_errors)
    
        return isValidForm;
    }

    const onSubmitResetPasswordRequest = (e) => {
        e.preventDefault();
        setInProgress(true);

        if(onValidateResetPasswordFormData()){
            axios.post(ApiBaseUrl + 'send-reset-password', {
                email: email
            })
                .then(res => {
                    setInProgress(false);
                    if(res.data.success && res.data.exist){
                        document.getElementById('reset-password-form').reset();
                        toast.success('Veuillez consulter votre boîte email pour réinitialiser votre mot de passe.', {
                            duration: 5000,
                            position: 'top-center',
                        });
                    }else if(!res.data.success && !res.data.exist){
                        console.log(res)
                        toast.error("L'adresse email renseigné, n'existe pas dans notre base de données. Veuillez réessayer en renseignant l'adresse email que vous aviez fourni à la création de votre compte.", {
                            duration: 5000,
                            position: 'top-center',
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    setInProgress(false);
                    toast.error("Une erreur est survenue. réessayer plus tard.", {
                        duration: 5000,
                        position: 'top-center',
                    });
                })
        }
        
    }


    return (
        <div className="dashboard-auth-component">
            <header>
            <div className="col-md-12 d-flex justify-content-center p-block-20 header-navbar">
                <NavLink to="/">
                <img className="logo-inscription" src={Logo} alt="Mon Espace Campus France" />
                </NavLink>
            </div>
            </header>
            <section id="auth-form-section">
                <div className="container auth-form-container">
                    <div className="auth-form-body">
                        <div className="auth-form-content">
                            <h3 className="auth-form-title">Demande de modification de votre mot de passe</h3>
                            <form id="reset-password-form">
                                <div className="row auth-form-row">
                                    <div className="col-md-12 auth-input-col">
                                        <div className="auth-input-container form-group">
                                            <input onChange={(e) => setEmail(e.target.value)} placeholder="Votre adresse email" className="form-control auth-form-control" name="email" type="email" id="email" />
                                        </div>
                                        {email_empty &&
                                            <div className="alert alert-danger error-alert text-center">
                                                {errors.email}
                                            </div>
                                        }
                                        {email_syntax_error &&
                                            <div className="alert alert-danger error-alert text-center">
                                                {errors.email_syntax_error}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-12 auth-submit-btn-col">
                                        {inProgress ?
                                            <button className="btn btn-success auth-submit-btn">envoi en cours</button> :
                                            <button type="submit" onClick={(e) => onSubmitResetPasswordRequest(e)} className="btn btn-success auth-submit-btn">envoyer</button>
                                        }
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    )
}

export default ResetPasswordRequest;


